 <template>
	<el-dialog title="批量录入线路列表" top="1vh" width="80%" :visible.sync="is_show_in_page" @close="close">
        <el-form class="ser_form" size="small"  label-width="68px">

			<el-form-item label-width="0">
				<el-button type="success" @click="sub(0)">确认录入</el-button>
			</el-form-item>
		</el-form>
	<div class="tab_height">
			<el-table :data="bl_list1"  :border="true" :stripe="true" size="small" height="100%">
				<el-table-column label="线路名称">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.bl_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.case_addr}}</div>
						<div class="tab_line_item">联系人:{{scope.row.case_link_man}} / {{scope.row.case_link_tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.aim_addr}}</div>
						<div class="tab_line_item">联系人:{{scope.row.aim_link_man}} / {{scope.row.aim_link_tel}}</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="收货/销售方信息">
					<template slot-scope="scope">
						<div class="tab_line_item" v-if="scope.row.consignee">收货方名称:{{scope.row.consignee}}</div>
						<div class="tab_line_item" v-if="scope.row.consigneeid">收货方信用代码:{{scope.row.consigneeid}}</div>
						<div class="tab_line_item" v-if="scope.row.seller_name">销售方名称:{{scope.row.seller_name}}</div>
						<div class="tab_line_item" v-if="scope.row.seller_code">销售方信用代码:{{scope.row.seller_code}}</div>
					</template>
				</el-table-column> -->
				<el-table-column label="单价">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.price}}元 / {{scope.row.price_unit}}</div>
					</template>
				</el-table-column>
				<el-table-column label="货物名称"  width="130">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.cargo_name}}/{{scope.row.cargo_type}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运费信息"  width="120">
					<template slot-scope="scope">
						<div class="tab_line_item">结算:{{scope.row.settlement_type_text}}</div>
						<div class="tab_line_item">运费:{{scope.row.freight_total}}</div>
					</template>
				</el-table-column>
				<el-table-column label="必填项/回程"  width="265">
					<template slot-scope="scope">
						<div style="display:flex;justify-content: space-between;">
							<div>
								<div class="tab_line_item">必填重量:{{scope.row.is_need_weight_text}}</div>
								<div class="tab_line_item">必填体积:{{scope.row.is_need_volume_text}}</div>
							</div>
							<div>
								<div class="tab_line_item">自动发车:{{scope.row.is_auto_tord_start_text}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="操作" width="160">
					<template slot-scope="scope">
						<el-button @click="bl_del(scope.row)" size="mini" type="text" style="margin-left:10px">删除</el-button>
					</template>
				</el-table-column> -->
			</el-table>
		</div>

	</el-dialog>
</template>

<script>
	import { Loading } from 'element-ui';
	export default {
		props:{
			is_show:Number,
            bl_list:Array
		},
		data() {
			return {
				
				//是否显示
				is_show_in_page:false,

                n:0,

				bl_fail_list:[],//录入失败得线路

				bl_list1:[]
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					console.log(this.bl_list)
					this.bl_list1=this.bl_list
					//打开弹出层
					this.is_show_in_page=true;
					
                   

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		methods: {
			//删除
			bl_del(item){
				console.log(item)
			},
			//关闭 清空数据
			close(){
				this.bl_fail_list = []
				this.bl_list1 = []
				this.n = 0
				this.$emit('closed')
			},
            //批量提交
            sub(n){
				Loading.service(this.options);
                let bl_info = this.bl_list[n]
				if(!bl_info){
					let loadingInstance = Loading.service(this.options);
						this.$nextTick(() => {
						loadingInstance.close();
					});
					//置入录入失败的线路
					if(this.bl_fail_list.length>0){
						this.bl_list1 = this.bl_fail_list
					}else{
						//全部成功 关闭页面刷新
						this.$my.other.msg({
							type:'success',
							str:'操作成功',
						})
						this.$emit("closed");
						this.is_show_in_page=false;
					}
					return
				}
                //调用接口
                this.$my.net.req({
					take_over_control:1,
                    data:{
                        mod:'truck_tord_real_time',
                        ctr:'tord_bl_excel_add',
                        case_addr:bl_info.case_addr,
						aim_addr :bl_info.aim_addr ,
						case_link_man:bl_info.case_link_man,
						case_link_tel:bl_info.case_link_tel,
						aim_link_man:bl_info.aim_link_man,
						aim_link_tel:bl_info.aim_link_tel,
						cargo_name:bl_info.cargo_name,

						cargo_type :bl_info.cargo_type ,
						settlement_type:bl_info.settlement_type,
						is_need_weight:bl_info.is_need_weight,
						is_need_volume:bl_info.is_need_volume,

						is_auto_tord_start:bl_info.is_auto_tord_start ,
						freight_total:bl_info.freight_total,
						price:bl_info.price,
						price_unit:bl_info.price_unit,
						consigneeid:bl_info.consigneeid,
						bl_name:bl_info.bl_name,
						consignee:bl_info.consignee,
						seller_code:bl_info.seller_code,
						seller_name:bl_info.seller_name
                    },
                    callback:(data)=>{
						if(data.code != 0){
							this.$my.other.msg({
								type:'info',
								str:data.error_info,
							})
							this.bl_fail_list.push(bl_info)
						}
						this.n++
						this.sub(this.n)
                    }
                })
            },
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.tord_real_time_add_bottom_btns{
		margin-top: 40px;
		text-align: center;
	}

	.el-dialog-s{
		z-index: 11;
	}

</style>